<template>
  <div v-if="isLoading" class="bg">
    <div id="loading"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollY: 0,
    };
  },

  updated() {
    if (
      this.$store.state.common.mainIdx == 0 &&
      this.$store.state.common.setSlidePopup.flag == 'start' &&
      this.$store.state.common.setSlidePopup.pages == 'ExecutionBanner'
    ) {
      // ExecutionBanner이 떠있을때, lock/unlock과 관계없이 상단으로 이동하도록 수정 mjkim 25.02.14
      this.scrollTop();
    } else {
      this.isLoading ? this.lockParentScroll() : this.unLockParentScroll();
    }
  },

  methods: {
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.body.style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.body.style = '';
      this.scrollTop(); // 스크롤 잠금해제 후 페이지 상단으로 이동하도록 로직 분리 mjkim 25.02.14
    },
    scrollTop() {
      // unLockParentScroll에서 분리 mjkim 25.02.14
      if (
        //서비스이용안내에서 버튼클릭 후 다른 페이지 진입시 중간부터 화면이 보여지는 것을 막음
        this.$route.name === 'tvpointUseDetail' ||
        this.$route.name === 'tvpayUseDetail' ||
        this.$route.name === 'hiddenAsset' ||
        this.$route.name === 'tvpayManagement' ||
        this.$route.name === 'EventDetailView' ||
        this.$route.name === 'VodEventDetailView' ||
        this.$route.name === 'initPage' ||
        this.$route.name === 'storeInfo'
      ) {
        window.scrollTo(0, window.scrollY);
      } else {
        window.scrollTo(0, this.scrollY);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.2);
  display: table;
}

#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 96px;
  height: 96px;
  margin-left: -50px;
  border: 10px solid #f2f3f5;
  border-radius: 50%;
  border-top-color: #2b2d2f;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 99999;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
