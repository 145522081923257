<template>
  <div class="header-swiper-wrapper" :style="isShowScrollNavbar ? 'top:120px' : 'top:0'">
    <swiper class="swiper" v-bind="swiperOptions" @swiper="onSwiper">
      <swiper-slide
        v-for="(menu, idx) in !$route.fullPath.includes('/bbarshop') ? fitMenuList : bbarshopMenuList"
        :key="idx"
        style="width: fit-content"
        :style="idx < fitMenuList.length - 1 ? 'padding-right: 15px' : idx === 4 ? 'padding-right: 100px' : ''"
      >
        <input
          :id="menu.id"
          :ref="'menu' + idx"
          v-model="selectTab"
          type="radio"
          :value="idx"
          :hidden="true"
          :name="'tab' + idx"
          @click="onClickTab(idx)"
        />
        <label :for="menu.id" class="pay-store normal">
          {{ menu.title }}
        </label>
      </swiper-slide>
    </swiper>
    <BorderBasic height="2" background="#dadcde" class="border" />
    <div v-if="selectTab === 0 && $route.fullPath.includes('/initPage') && !$commons.isNullObject(notificationData)">
      <div class="notification-wrapper d-flex grid">
        <img class="tool-tip" :src="require('@/assets/img/common/img_tooltip.svg')" alt="" />
        <div class="notification-box d-flex c-center r-center">
          <CommonText font-size="26" font-weight="normal" spacing="-1.04" font-color="#ffffff" line-height="38">{{
            notificationData.contents
          }}</CommonText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css';

import bus from '@/utils/bus';
import CommonText from '@/components/common/text/CommonText';
import BorderBasic from '@/components/common/border/BorderBasic';
import $store from '@/store';

export default {
  name: 'HeaderSwiper',

  components: {
    BorderBasic,
    CommonText,
  },

  props: {
    isShowScrollNavbar: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },

  emits: ['changePage'],

  data() {
    return {
      swiper: null,
      selectTab: -1,
      fitMenus: [],
      bbarshopMenus: [],
      swiperOptions: {
        freeMode: true,
        slidesPerView: 'auto',
      },
    };
  },

  computed: {
    mainIdx() {
      return this.$store.state.common.mainIdx;
    },
    fitMenuList() {
      return this.fitMenus;
    },
    bbarshopMenuList() {
      return this.bbarshopMenus;
    },
    notificationData() {
      return this.$store.state.fit.notificationList;
    },
  },

  watch: {
    value(newValue) {
      this.selectTab = newValue;
      // jhkim: vue3: 인터벌 없이 스와이퍼 위치가 변경되지 않음
      setTimeout(() => {
        this.$commons.setCenteredSwiper(this.swiper, newValue, 300);
      }, 10);

      if (newValue === 0) {
        this.getNotificationList();
      }
    },
  },

  created() {
    bus.$emit('set:init');

    this.fitMenus = $store.state.fit.fitMenus;
    this.bbarshopMenus = $store.state.bbarshop.bbarshopMenus;
  },

  mounted() {
    // jhkim: vue3:
    this.selectTab = this.$store.state.common.mainIdx;
    this.$commons.setCenteredSwiper(this.swiper, this.selectTab, 0);
    this.getNotificationList();
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    async onClickTab(idx) {
      if (idx === this.$store.state.common.mainIdx) return;
      await this.$store.commit('setLastScrollPosition', 0);
      await this.$emit('changePage', idx);
    },

    // jhkim: vue3: service 변경 시 홈 -> 홈으로 이동하면 swiper 위치가 초기화 되지 않아 구현
    init() {
      this.selectTab = 0;
      setTimeout(() => {
        this.$commons.setCenteredSwiper(this.swiper, 0, 0);
      }, 10);
    },

    getNotificationList() {
      this.$store.dispatch('notification').then(res => {
        this.$commons.printLog('getNotification res:: ', res);
        if (!this.$commons.isNull(res)) {
          this.$commons.printLog('getNotificationList :: ', res.notification_components[0]);
          this.$store.commit('setNotificationList', res.notification_components[0]);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  /*border: 2px solid blue;*/
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.header-swiper-wrapper {
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 15;
  box-sizing: border-box;
  background-color: #fff;
}
.swiper {
  padding-left: 40px;
  padding-right: 40px;
}

.dark {
  background-color: #2b2d2f;
}
.normal {
  color: #999999;
}
.pay-store {
  padding-right: 17px;
  padding-left: 17px;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 54px;
  display: inline-block;
  height: 70px;
  &.light {
    color: #f2f3f5;
  }
}

input:checked + .pay-store {
  box-sizing: border-box;
  color: #343534;
  border-bottom: 6px solid #343534;
  font-weight: 500;

  &.light {
    color: #ffffff;
    border-bottom: 6px solid #ffffff;
  }
}
.notification-wrapper {
  margin-top: 8px;
  position: absolute;
  left: 41%;
  width: 100%;
}
.tool-tip {
  width: 102px;
  height: 78px;
}
.notification-box {
  width: fit-content;
  min-width: 70px;
  background-color: #2b2d2f;
  padding: 13px 16px;
  border-radius: 12px;
  position: inherit;
  top: 14px;
}
</style>
