// jhkim
import Cache from '@/modules/ft-cache';
import Logger from '@/modules/ft-logger';
import { Service, Key } from '@/modules/ft-constants';
import FTInitSetUp from '@/modules/ft-init-setup';
import FTObject from '@/modules/ft-object';

import $store from '@/store';
import $utils from '@/utils/tvPointcommon';
import { fitMixin } from '@/utils/fitMixin';
import bus from '@/utils/bus';
import $commons from '@/utils/commons';

const common = [
  // {
  // 	path: '/',
  // 	redirect: '/initPage',
  // },
  {
    path: '/initPage',
    name: 'initPage',
    component: () => import('@/views/initPage'),
    async beforeEnter(to, from, next) {
      let menuIndex = 0;

      // jhkim: 뒤로가기 선택시 다시 라우터 로직을 타므로, 현재 페이지로 가기 위해 설정
      // 주문완료 페이지에서 initPage로 돌아올 경우 핏콜라보 홈으로 랜딩 mjkim 2025-03-20
      const currentMenu = Cache.get(Key.CURRENT_MENU);
      if (FTObject.isNotNil(currentMenu) && currentMenu.serviceType == Service.FIT && from.name !== 'OrderComplete') {
        menuIndex = currentMenu.menuIndex;
      }
      //단말기에서 들어온 url의 경우, 티비포인트로 들어 올때 단말을 등록해야 함
      // jhkim: 첫 진입시에만 페이지 목록 다운
      //await $store.dispatch('fitPageList');
      $commons.printLog('☆ router index.js initPage 진입 fullPath', from.fullPath);

      // const hasMenu = Cache.get(Key.HAS_MENU);
      if (from.fullPath === '/' || from.fullPath.includes('/permissionScreen') || $commons.isNull($store.state.fit.pageDepth2[0])) {
        // if (hasMenu !== true) {
        // Cache.set(Key.HAS_MENU, true);
        await $store.dispatch('fitPageList');
        //fit 메뉴 탭 이름 변경
        const fitMenus = FTInitSetUp.makeMenus(Service.FIT);
        $store.commit('setFitMenus', fitMenus);

        //빠숍 메뉴 탭 이름 변경
        const bbarshopMenus = FTInitSetUp.makeMenus(Service.BSHOP);
        $store.commit('setBbarshopMenus', bbarshopMenus);

        // jhkim: 순서 점검 필ㅛ...
        Logger.debug('router: fit: %s', JSON.stringify(fitMenus));
        Logger.debug('router: fit: %s', JSON.stringify(bbarshopMenus));
      } else if (from.fullPath.indexOf('FIT00FIT01') > 0) {
        // 핏 - 핏콜에서 들어왔을시, 쿼리 추가(fitmixin) 되기 때문에 다시 init으로 돌아올때 쿼리로 판별 후 핏콜일때만 초기화 espark
        $store.commit('setSourceId', '');
      }

      // jhkim: 현재 서비스, 시작 메뉴 저장
      Cache.set(Key.CURRENT_MENU, { serviceType: Service.FIT, menuIndex: Number(menuIndex) });
      Logger.debug('router: fit: %d, %d, 0', Service.FIT, Number(menuIndex));

      // if ($store.state.tvpoint.isLogin) await $store.dispatch('newCount'); // 파이 툴팁 개수 확인(아직 서버 반영안됨);

      // if ($store.state.Rcommon.deviceType === 1 && from.fullPath === '/') {
      // 	$commons.printLog('call window.Android.splashOff');
      // 	window.Android.splashOff();
      // }

      next();

      // jhkim: 개선의 소지가 보임. 페이지 로딩 완료보다 먼저 실행됨
      //bus.$emit('end:loading');

      if (
        !$store.state.bbarshop.mallCode ||
        $store.state.bbarshop.mallCode === 'POPUP' ||
        $store.state.bbarshop.mallCode === 'FAV_GOODS' ||
        $store.state.bbarshop.mallCode === 'FAV_STORE' ||
        $store.state.bbarshop.mallCode === 'RECENT_GOODS' ||
        $store.state.bbarshop.mallCode === 'SEARCH' ||
        $store.state.bbarshop.mallCode === 'TAG' ||
        $store.state.bbarshop.mallCode === 'STORE' ||
        $store.state.bbarshop.mallCode === 'PUSH' ||
        $store.state.bbarshop.mallCode === 'STORE' ||
        $store.state.bbarshop.mallCode === 'PUSH' ||
        $store.state.bbarshop.mallCode === 'LMS'
      ) {
        const menu = Cache.get(Key.CURRENT_MENU);
        const pageData = $store.state.fit.pageDepth1.filter(data => data.menu_type == menu.serviceType);
        $store.commit('setMallCode', pageData[$store.state.common.mainIdx].uri);
      }
    },
  },
  {
    path: '/onBoarding',
    name: 'onBoard',
    component: () => import('@/views/common/onBoard'),
  },
  {
    path: '/permissionScreen',
    name: 'permissionScreen',
    component: () => import('@/views/user/permissionScreen'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/menu'),
    async beforeEnter(to, from, next) {
      bus.$emit('start:loading');
      $store
        .dispatch('getNoticeList', {
          termType: $store.getters.getTermType,
          siteId: 'WEB',
        })
        .then(data => {
          if (parseInt(data.resultCode) === 0) {
            const today = new Date().getTime();
            const _data = [];
            let noticeDay = null;
            for (const idx in data.noticeList) {
              noticeDay = new Date(data.noticeList[idx].regDate).getTime();

              // 일주일 내에 공지가 떠야 한다.(운영)
              if (today <= noticeDay + 86400000 * 7) {
                _data.push(data.noticeList[idx]);
              }
            }
            $store.commit('setNoticeList', _data);
          }
        });

      // 메뉴 타입 커밋
      if (from.fullPath.startsWith('/initPage')) {
        $store.commit('setTypePath', 0);
      } else if (from.fullPath === '/bbarshop') {
        $store.commit('setTypePath', 1);
      }

      // 메뉴 핏, 빠숍 배너 한번에 저장
      let typePath = $store.state.bbarshop.typePath ? $store.state.bbarshop.typePath : 0;
      let pageUriList = ['/common/more/fit', '/common/more/shopping'];
      let menuBannerList = [];
      for (let pageUri of pageUriList) {
        const pageId = $store.state.fit.pageDepth1.filter(data => data.uri == pageUri);
        await $store.dispatch('componentTemplateGroupList', pageId[0].id).then(res => {
          res.group_templates[0].component_templates.forEach(component => {
            // for (let component of group.component_templates) {
            const params = {
              pageId: pageId[0].id,
              componentId: component.id,
              groupId: res.group_templates[0].id,
            };

            if (component.type === 14) {
              params.groupType = 'banners';
              $store.dispatch('fitBasicApi', params).then(data => {
                menuBannerList.push(data);
                $store.commit('setRollingBannerArrayList', menuBannerList);
                $store.commit('setRollingBannerList', menuBannerList[typePath]);
              });
            }
          });
        });
      }
      bus.$emit('end:loading');

      $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
        ? $store.state.fit.pageInform.pageInfo
        : {};
      $store.state.fit.pageInform.pageInfo = null;
      next();
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('pushAlarmList');

      next();
    },
  },
  {
    path: '/notifications/notificationSettings',
    name: 'notificationSettings',
    component: () => import('@/views/notifications/notificationSettings'),
    async beforeEnter(to, from, next) {
      if ($store.state.tvpoint.isLogin) {
        await $store.dispatch('notificationList');
      }
      next();
    },
  },
  {
    path: '/termView',
    name: 'termView',
    component: () => import('@/views/common/terms'),
    async beforeEnter(to, from, next) {
      try {
        const saveData = {
          tvpaySubUrl: '/api/saveData.mv',
          // termId: '',
          termId: $store.state.tvpoint.termId,
          storeId: '',
        };

        const data = await $store.dispatch('callRelayRpServer', saveData);
        $store.commit('setRpSession', data.resultData.jSession);
        const orderTerms = {
          tvpaySubUrl: `/api/getOrderTerms.mv;jsessionid=${data.resultData.jSession}`,
          cardCode: '1011',
          methodType: 'SMART_PAY',
          userType: 'mobile_pay',
          formType: 'tv_card_new',
          clientType: 'mobile',
        };

        let res;
        if (parseInt(data.resultCode) === 0) {
          res = await $store.dispatch('callRelayRpServer', orderTerms);
        } else {
          next(false);
        }

        if (parseInt(res.resultCode) === 0) {
          const list = res.resultData.list;
          const termsList = list.filter(data => data.userType === 'mobile_pay');
          $store.commit('setTermsTitleList', termsList);

          next();
        } else {
          next(false);
        }
      } catch (error) {
        throw new Error(`fail to open terms ${error}`);
      }
    },
  },
  {
    path: '/serviceInfo',
    name: 'serviceInfo',
    component: () => import('@/views/common/serviceInfo'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms'),
  },
];

export default common;
