<template>
  <div>
    <div class="popup-wrap">
      <div class="popup-content">찜 설정은 로그인 후 이용 가능합니다.</div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-no" @click="confirm">닫기</button>
        <button class="popup-button-yes" @click="goLogin">로그인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeedToLoginPopup',

  methods: {
    async goLogin() {
      await this.$store.commit('alertPopup', {});
      await this.$router.push('/user/login');
    },
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 40px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
</style>
