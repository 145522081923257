import { getHomePointData } from '@/apis/user';

const getDefaultState = () => {
  return {
    iosPush: 0,
    // 안드로이드 퍼미션 설정
    isFirst: false,
    permission: {}, //permission 동의여부 조회 결과
    token: null, //firebase token
    saveDeviceId: undefined, //디바이스 아이디 고유 값, 폰넘버 아님(키값)
    deviceType: '', // 안드로이드 사용여부 확인    APP(ANDROID):1, IOS:3, WEB:2
    deviceName: '', // 안드로이드 아이폰 web 구분  ANDROID, IOS, WEB
    extractPhoneNumber: undefined, //휴대폰 번호
    autoLoginCookie: null, // 자동로그인 쿠키값
    userAgent: null,
    OS: null,
    refreshYN: false, // 웹에서 새로고침을 했을 경우 App.vue에서 1회만 처리하기 위한 값
    initFlag: false, // 최초 진입 여부 확인
    // 1: Android App
    // 2: Web
    // 3: iOS App
    pushAgreeDate: '',
    test: '',
    // initPage 현재 페이지 위치
    mainIdx: 0,
    home: Boolean, // 홈 색상 판단하기 위해
    // initPage 회면 수
    allPage: 6,
    // 공통 헤더 표기 여부
    isHeader: false,
    // 마지막 스크롤 위치 정보
    lastScrollPosition: 0,
    //토스트 팝업
    toastPopup: { content: '' },
    componentPopup: {},
    // CutomVideo.vue 파일 재생용
    productDetail: {
      thumbnail_images: '',
      url: '',
      video_url: '',
      paddingHeight: 10,
    },
    // initPage/index 에서 아래에서 올라오는 슬라이딩 팝업 사용하기 위한 값.
    setSlidePopup: {
      showSlide: false,
      pages: '',
      height: '',
      flag: '',
    },
    tutorialModal: {
      show: false,
      pages: '',
    },
    serviceInfoType: '',
    getHomePointData: {},
    userRequestRedirectPage: '', //사용자가 로그인 전에 가려고 했더 페이지 uri 저장
    fetchSubscribeList: {},
    agreePushPopup: false, // 푸쉬 동의 팝업
    privacyRevisionList: [],
    isPrivacyTerms: false,
    sourceId: '', // 핏콜 제휴사 페이지에서 인앱 결제 상품 구매하기 시도 시 핏콜 제휴사 ID
    toPath: '',
    fromPath: '',
    fromMenu: '/',
    appView: false, // 앱 실행 시 로딩 이미지
    alarmId: '', // 푸시 알림 ID
    orderCompletePath: '',
    eventId: '',
    componentType: null,
    salesPlatform: '', // 판매사 구분 코드 ( FIT00FIT01 : 핏콜라보 핏콜, FITSH00001 : 핏콜 제휴사 신한, BBARS000001 : 핏콜라보 빠숍)
    bannerId: '', // 배너 ID( 구매 상품이 편성된 컴포넌트 ID, APP PUSH의 경우 PUSH ID)
    fitcolType: '', // 핏콜 제휴사 페이지 진입 경로 구분(APP: 핏콜 제휴사 앱, A: 핏콜 제휴사 앱 푸시, L: 핏콜 제휴사 문자 발송
    fitSourceId: '',
    clickBack: false,
    exitPopUp: false,
    agreePushYN: '',
    aosNotifications: false,
    appcardTransNo: '', // 앱카드 인증 서버 인증 거래번호
    appcardToken: '', // 앱카드 인증 서버 토큰
  };
};

export default getDefaultState();
