const getDefaultState = () => {
  return {
    fitMenus: [],
    isShowVideo: false,
    executionBannerList: {},
    fitSelectType: '',
    pageDepth1: Array,
    pageDepth2: Array,
    pagesId: {},
    categoriesList: {},
    rollingBannerList: {},
    rollingBannerArrayList: [],
    //dh
    productListArray: [],
    groupsData: [],
    paramsArray: [],
    bsGroupsApi: [],
    productSchedule: {},
    bsProductsDetail: [],
    //
    scrollBannerList: {},
    vodPlatformScrollBannerList: {},
    eventAccumulateScrollBannerList: {},
    gridBannerList: {},
    vodEventScrollBannerList: {},
    eventScrollBannerList: {},
    componentVodList: {},
    componentVodListParams: {},
    componentBenefitList: [],
    componentBenefitListHome: {},
    componentTemplateGroupList: [],
    fitTvPointUse: [],
    fitTvPayUse: [],
    fitPointUseDetail: {},
    eventDetail: {},
    paiCollectEvent: [],
    vodDetail: {},
    magazineList: {},
    exhibitionList: {},
    productRollingList: {},
    gridProductList: {},
    productList: {},
    productDesignList: {},
    productImageRollingList: {},
    productImageList: {},
    productContent: {},
    exhibitionAList: {},
    exhibitionBList: {},
    categoryRollingList: {},
    categoryList: {},
    categoryScrollList: {},
    exhibitionDetail: [],
    pageInform: {
      prevPageInfo: {},
      pageInfo: {},
    },
    clickLink: null,
    notificationList: {},
  };
};

export default getDefaultState();
