// jhkim: idx:
import { Service } from '@/modules/ft-constants';

import $commons from '@/utils/commons';
import bus from '@/utils/bus';

import {
  executionBannerList,
  fitPageList,
  componentTemplateGroupList,
  fitBasicApi,
  fitGroupsApi,
  fitBasicApiDetail,
  fitPointUse,
  fitPointUseDetail,
  eventDetail,
  eventList,
  requestPartner,
  exhibitionDetail,
  componentTemplateGroupListFitcol,
  notification,
} from '@/apis/fit';

const actions = {
  async executionBannerList({ commit }, payload) {
    const { data } = await executionBannerList();
    if (!$commons.isNull(data)) {
      bus.$emit('end:loading');
      commit('setExecutionBannerList', data);
      commit('setSlideUpPopup', {
        showSlide: true,
        pages: 'ExecutionBanner',
        height: '784px',
        flag: payload ? 'end' : 'start',
        // flag: 'start',
      });
    }
  },

  async fitPageList({ commit }) {
    const { data } = await fitPageList();
    const depth1 = data ? data.page_templates.filter(data => data.depth === 1) : [];
    const depth2 = data ? data.page_templates.filter(data => data.depth === 2) : [];

    // jhkim: idx: 다른 곳에서 order를 페이지 번호로 사용한 경우가 있다. idx를 부여하여 idx를 페이지 번호로 사용할 수 있게끔 변경
    let pageData = depth1.filter(data => data.menu_type == Service.FIT);
    for (let i in pageData) {
      pageData[i].idx = i;
    }

    pageData = depth1.filter(data => data.menu_type == Service.BSHOP);
    for (let i in pageData) {
      pageData[i].idx = i;
    }

    commit('setPageDepth1', depth1);
    commit('setPageDepth2', depth2);
    return depth1;
  },

  async componentTemplateGroupList({ commit }, payload) {
    const { data } = await componentTemplateGroupList(payload);
    commit('setComponentTemplateGroupList', data);
    return data;
  },

  async componentTemplateGroupListFitcol({ commit }, payload) {
    const { data } = await componentTemplateGroupListFitcol(payload);
    commit('setComponentTemplateGroupList', data);
    return data;
  },

  async fitBasicApi({ commit }, payload) {
    const { data } = await fitBasicApi(payload);
    return data;
  },

  //dh---
  async fitGroupsApi({ commit }, payload) {
    const { data } = await fitGroupsApi(payload);
    return data;
  },

  async fitBasicApiDetail({ commit }, payload) {
    const { data } = await fitBasicApiDetail(payload);
    return data;
  },

  async fitPointUse({ commit }, payload) {
    const { data } = await fitPointUse(payload);

    if (payload === 1) {
      commit('setFitTvPointUse', data);
    } else if (payload === 2) {
      commit('setFitTvPayUse', data);
    }
  },

  async fitPointUseDetail({ commit }, payload) {
    const { data } = await fitPointUseDetail(payload);
    commit('setFitPointUseDetail', data);
  },

  async eventDetail({ commit }, payload) {
    const { data } = await eventDetail(payload);
    return data;
  },

  async eventList({ commit }) {
    const { data } = await eventList();
    return data;
  },

  async requestPartner({ commit }, payload) {
    const { data } = await requestPartner(payload);
    return data;
  },

  async exhibitionDetail({ commit }, payload) {
    const { data } = await exhibitionDetail(payload);
    return data;
  },

  async notification({ commit }, payload) {
    const { data } = await notification(payload);
    return data;
  },
};

export default actions;
