import common from './index';

const mutations = {
  setFitMenus(state, payload) {
    state.fitMenus = payload;
  },
  setMainPage(state, payload) {
    state.mainPage = payload;
  },
  setExecutionBannerList(state, payload) {
    state.executionBannerList = payload;
  },
  fitSelectType(state, payload) {
    state.fitSelectType = payload;
  },
  setPageDepth1(state, payload) {
    state.pageDepth1 = payload;
  },
  setPageDepth2(state, payload) {
    state.pageDepth2 = payload;
  },
  setComponentTemplateGroupList(state, payload) {
    state.componentTemplateGroupList = payload;
  },
  setProductRollingList(state, payload) {
    state.productRollingList = payload;
  },
  setRollingBannerList(state, payload) {
    state.rollingBannerList = payload;
  },
  setRollingBannerArrayList(state, payload) {
    state.rollingBannerArrayList = payload;
  },
  setGroupsData(state, payload) {
    state.groupsData = payload;
  },
  setBsGroupsApi(state, payload) {
    state.bsGroupsApi = payload;
  },
  productSchedule(state, payload) {
    state.productSchedule = payload;
  },
  bsProductsDetail(state, payload) {
    state.bsProductsDetail = payload;
  },
  //
  setScrollBannerList(state, payload) {
    state.scrollBannerList = payload;
  },
  setVodPlatformScrollBannerList(state, payload) {
    state.vodPlatformScrollBannerList = payload;
  },
  setEventAccumulateScrollBannerList(state, payload) {
    state.eventAccumulateScrollBannerList = payload;
  },
  setEventScrollBannerList(state, payload) {
    state.eventScrollBannerList = payload;
  },
  setGridBannerList(state, payload) {
    state.gridBannerList = payload;
  },
  setComponentVodList(state, payload) {
    state.componentVodList = payload;
  },
  setComponentVodListParams(state, payload) {
    state.componentVodListParams = payload;
  },
  setComponentBenefitList(state, payload) {
    state.componentBenefitList = payload;
  },
  setComponentBenefitListHome(state, payload) {
    state.componentBenefitListHome = payload;
  },
  setFitTvPointUse(state, payload) {
    state.fitTvPointUse = payload;
  },
  setFitTvPayUse(state, payload) {
    state.fitTvPayUse = payload;
  },
  setFitPointUseDetail(state, payload) {
    state.fitPointUseDetail = payload;
  },
  setEventDetail(state, payload) {
    state.eventDetail = payload;
  },
  setPaiCollectEvent(state, payload) {
    state.paiCollectEvent = payload;
  },
  setVodDetail(state, payload) {
    state.vodDetail = payload;
  },
  RESET_COMMON_STATE(state) {
    Object.assign(state, common.defaultState);
  },
  setExhibitionDetail(state, payload) {
    state.exhibitionDetail = payload;
  },
  setPageInform(state, payload) {
    state.pageInform = payload;
  },
  setNotificationList(state, payload) {
    state.notificationList = payload;
  },
};

export default mutations;
