<template>
  <div class="execution-popup">
    <div v-if="bannerListLength > 1">
      <swiper class="swiper" v-bind="swiperOptions" @swiper="onSwiper">
        <swiper-slide v-for="(item, index) in dataList" :key="index.priority" class="execution-slide" style="">
          <img :src="item.component_upload" alt="" @click="clickEvent('eventDetail', index)" />
        </swiper-slide>
        <div ref="pagination" class="swiper-pagination" @click="clickEvent('showEventList')"></div>
      </swiper>
    </div>
    <div v-else-if="bannerListLength === 1" class="execution-slide" style="margin-left: 40px">
      <img :src="dataList[0].component_upload" alt="" @click="clickEvent('eventDetail', 0)" />
    </div>

    <div class="control-box d-flex c-center r-space-between">
      <CommonText v-if="getBannerType === 'end'" font-size="28" font-color="#2b2d2f" spacing="-1.12" @click="clickEvent('exit')"
        >종료</CommonText
      >
      <div v-else class="d-flex c-center r-center" @click="clickEvent('closeForToday')">
        <img
          class="check-btn"
          :src="isHiddenToday ? require('@/assets/img/common/ic_check_on.svg') : require('@/assets/img/common/ic_check_off.svg')"
        />
        <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.12">오늘 하루 그만보기</CommonText>
      </div>
      <img
        :src="require('@/assets/img/common/ic_close_dkgray.svg')"
        style="width: 24px; object-fit: contain"
        @click="clickEvent('closeSlidingPopup')"
      />
    </div>
  </div>
</template>

<script>
import { fitMixin } from '@/utils/fitMixin';
import CommonText from '@/components/common/text/CommonText';

import 'swiper/css';

export default {
  name: 'ExecutionBanner',

  components: { CommonText },

  mixins: [fitMixin],

  data() {
    return {
      swiper: null,
      swiperOptions: {
        watchOverflow: true,
        slidesPerView: 'auto',
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        spaceBetween: 32,
        on: {},
        nested: true,
      },
      isHiddenToday: false,
    };
  },

  computed: {
    dataList() {
      return !this.$commons.isNull(this.$store.state.fit.executionBannerList) ? this.$store.state.fit.executionBannerList.item_list : [];
    },
    bannerListLength() {
      return this.$commons.isNull(this.dataList) ? -1 : this.dataList.length;
    },
    getBannerType() {
      return this.$store.state.common.setSlidePopup.flag ?? '';
    },
  },

  activated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.resume();
    }
  },

  deactivated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.pause();
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    async clickEvent(sort, idx) {
      switch (sort) {
        case 'eventDetail':
          this.$store.commit('setMallCode', 'POPUP');
          this.$store.commit('setSalesPlatform', 'BBARS00001');
          if (!this.$commons.isUndefined(this.dataList) && !this.$commons.isNull(this.dataList[idx].id)) {
            this.$store.commit('setBannerId', this.dataList[idx].id);
          }
          this.sendEventLink(this.dataList[idx].link.value, this.dataList[idx].link.type);
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });

          // 튜토리얼 show 유무
          // if ((this.$deviceType === 1 || this.$deviceType === 3) && (await this.$bridge.stringValue('0')) !== 'home') {
          //   this.$store.commit('setTutorialModal', {
          //     show: true,
          //     pages: 'AppTutorial',
          //   });
          //   this.$bridge.setStringValue('0', 'home');
          // }
          break;
        case 'closeForToday': {
          const stopValue = this.isHiddenToday ? '' : this.$utils.getDate('bar');
          const cookieValue = this.isHiddenToday ? '' : 'N';

          if (this.$deviceType === 1 || this.$deviceType === 3) {
            this.$bridge.setStringValue('stopSee', stopValue);
          } else {
            this.$utils.setCookie('executionPopup', cookieValue, 1);
          }

          this.isHiddenToday = !this.isHiddenToday;

          break;
        }
        case 'closeSlidingPopup':
          this.$store.commit('setExitPopUp', false);
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });

          // 튜토리얼 show 유무
          // if ((this.$deviceType === 1 || this.$deviceType === 3) && (await this.$bridge.stringValue('0')) !== 'home') {
          //   this.$store.commit('setTutorialModal', {
          //     show: true,
          //     pages: 'AppTutorial',
          //   });
          //   this.$bridge.setStringValue('0', 'home');
          // }
          break;
        case 'showEventList':
          this.$store.commit('setComponentPopup', {
            show: true,
            pages: 'EventPopupBannerList',
            showMore: false,
            icon: 'close',
            name: 'modal',
          });
          break;
        case 'exit':
          if (this.$deviceType === 1) {
            this.$bridge.call('appExit');
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.execution-popup {
  margin: 40px 0;
}
.execution-slide {
  width: 400px;
  height: 600px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  background-color: #ffffff;
}
.control-box {
  height: 104px;
  margin-top: 40px;
  padding: 0px 40px;
  text-align: center;
  background: #ffffff;
}
.check-btn {
  width: 36px;
  height: 36px;
  object-fit: contain;
  margin-right: 12px;
}
</style>
