import common from './index';

const mutations = {
  setIosPush(state, payload) {
    state.iosPush = payload;
  },
  home(state, payload) {
    state.home = payload;
  },
  setCommonToastPopup(state, payload) {
    state.toastPopup = payload;
  },
  setComponentPopup(state, payload) {
    state.componentPopup = payload;
  },
  setProductDetail(state, payload) {
    state.productDetail = payload;
  },
  setMainIdx(state, payload) {
    state.mainIdx = payload;
  },
  setLastScrollPosition(state, payload) {
    state.lastScrollPosition = payload;
  },
  userAgent(state, payload) {
    state.userAgent = payload;
  },
  OS(state, payload) {
    state.OS = payload;
  },
  setInitFlag(state, payload) {
    state.initFlag = payload;
  },
  agreePushPopup(state, data) {
    state.agreePushPopup = data;
  },
  setRefreshYN(state, payload) {
    state.refreshYN = payload;
  },
  savePermission(state, payload) {
    state.permission = payload;
  },
  saveToken(state, payload) {
    state.token = payload;
  },
  saveDeviceId(state, payload) {
    state.saveDeviceId = payload;
  },
  saveExtractPhoneNumber(state, data) {
    state.extractPhoneNumber = data;
  },
  isFirst(state, data) {
    state.isFirst = data;
  },
  setPushAgreeDate(state, payload) {
    state.pushAgreeDate = payload;
  },
  saveAutoLoginCookie(state, data) {
    state.autoLoginCookie = data;
  },
  setSlideUpPopup(state, data) {
    state.setSlidePopup = data;
  },
  setTutorialModal(state, data) {
    state.tutorialModal = data;
  },
  setServiceInfoType(state, data) {
    state.serviceInfoType = data;
  },
  RESET_COMMON_STATE(state) {
    Object.assign(state, common.defaultState);
  },
  getHomePointData(state, data) {
    state.getHomePointData = data;
  },
  setDeviceType(commit, data) {
    commit.deviceType = data;
  },
  userRequestRedirectPage(commit, data) {
    commit.userRequestRedirectPage = data;
  },
  fetchSubscribeList(commit, data) {
    commit.fetchSubscribeList = data;
  },
  privacyRevisionList(commit, data) {
    commit.privacyRevisionList = data;
  },
  setSourceId(state, data) {
    state.sourceId = data;
  },
  setEventId(state, data) {
    state.eventId = data;
  },
  setToPath(state, data) {
    state.toPath = data;
  },
  setFromPath(state, data) {
    state.fromPath = data;
  },
  setFromMenu(state, data) {
    state.fromMenu = data;
  },
  setAppView(state, data) {
    state.appView = data;
  },
  setAlarmId(state, data) {
    state.alarmId = data;
  },
  setOrderCompletePath(state, data) {
    state.orderCompletePath = data;
  },
  setSalesPlatform(state, data) {
    state.salesPlatform = data;
  },
  setBannerId(state, data) {
    state.bannerId = data;
  },
  setFitcolType(state, data) {
    state.fitcolType = data;
  },
  setComponentType(state, data) {
    state.componentType = data;
  },
  setFitSourceId(state, data) {
    state.fitSourceId = data;
  },
  setClickBack(state, data) {
    state.clickBack = data;
  },
  setExitPopUp(state, data) {
    state.exitPopUp = data;
  },
  setAgreePushYN(state, data) {
    state.agreePushYN = data;
  },
  setAosNotifications(state, data) {
    state.aosNotifications = data;
  },
  setAppcardTransNo(state, data) {
    state.appcardTransNo = data;
  },
  setAppcardToken(state, data) {
    state.appcardToken = data;
  },
  setDeviceName: (state, data) => {
    state.deviceName = data;
  },
};

export default mutations;
